/*------------------------------------*\
    # INFO CALLOUTS
\*------------------------------------*/

.c-info {
  display: flex;   
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  @media all and (min-width: $bp-small) {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
  @media all and (min-width: $bp-medium) {
    margin-left: auto;
  }
}
.c-info__icon {
  margin-right: 0.5rem;
  height: 40px;
  width: 40px;
  path {
    fill: $blue-dark;
    .c-info--inverted & {
      fill: #fff;
    }
  }
}
.c-info__item {
  display: flex;
  align-items: center;
  margin-right: $pad/2;
  &:last-child {
    margin-right: 0;
  }
}
.c-info__text {
  white-space: nowrap;
  color: $blue-dark;
  font-family: $heading-font;
  line-height: 1;
  .c-info--inverted & {
    color: #fff;
  }
}
.c-info__text--primary {
  font-weight: bold;
  font-size: 1.2rem;
}
.c-info__text--secondary {
  font-size: 1.0rem;
}
