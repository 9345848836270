/*------------------------------------*\
    # FOOTER
\*------------------------------------*/

/* Site Footer */

.c-footer {
  background: $black;
  padding: $pad;
  border-top: solid 1px #2F3330; 
}
.c-footer,
.c-footer--nav {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  @media all and (min-width: $bp-small) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.c-footer--nav > * {
  margin-bottom: $pad/2;
  @media all and (min-width: $bp-small) {
    margin: 0;
  }
}
