/*------------------------------------*\
    # SOCIAL
\*------------------------------------*/

.c-social {
  display: flex;
  align-items: center;
  margin-top: $pad/4;
  @media all and (min-width: $bp-small) {
    margin-top: 0;
  }
}
.c-social__item {
  margin-right: $pad/2;
  &:last-child {
    margin-right: 0;
  }
}
.c-social__link {
  fill: #fff;
  transition: fill $timing-slow $easing;
  &:hover path {
    fill: $green-light !important;
    transition: fill $timing-fast $easing;
  }
}
