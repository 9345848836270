/*------------------------------------*\
	# HEADINGS
\*------------------------------------*/

h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font;
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1.3;
}

/*
 * Sizing based on 1.25 Major Third scale
 * See: https://type-scale.com/
 */

h1 { font-size: 3.2rem; }
h2 { font-size: 2.5rem; }
h3 { font-size: 2.0rem; }

@media all and (min-width: $bp-small) {
  h1 { font-size: 3.4rem; }
  h2 { font-size: 2.7rem; }
  h3 { font-size: 2.2rem; }
}
@media all and (min-width: $bp-medium) {
  h1 { font-size: 4.0rem; }
  h2 { font-size: 3.0rem; }
  h3 { font-size: 2.8rem; }
}
