/*------------------------------------*\
    # LOGO
\*------------------------------------*/

.c-logo {
  @media all and (min-width: $bp-small) {
    margin-right: $pad;
  }
  & a {
    display: block;
  }
  & svg {
    @media all and (min-width: $bp-small) {
      width: auto;
    }
  }
} 

// Inverted Variation

.c-logo--inverted {
  width: auto;
}

// Reduce size in footer

.c-footer .c-logo {
  width: 50%;
}
