/*------------------------------------*\
    # HEADER
\*------------------------------------*/

.c-header {
  position: relative;
  padding: $pad;
  background: #fff;
  overflow: auto;
  border-bottom: solid 6px $blue-light;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  > *:first-child {
    margin-bottom: $pad/2;
  }
  @media all and (min-width: $bp-medium) {
    justify-content: space-between;
    flex-wrap: nowrap;
    > *:first-child {
      margin-bottom: 0;
    }
  }
}

.c-header.is-active {
  position: fixed;
  width: 100vw;
  z-index: 2;
}
