/*------------------------------------*\
    # HERO
\*------------------------------------*/

.c-hero {
  position: relative;  
  border-bottom: solid 1px #ddd;
  @media all and (min-width: $bp-large) {
    border-bottom: 0;
  }
}
.c-hero__image {
  width: 100vw;
  height: auto;
}
