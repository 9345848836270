/*------------------------------------*\
    # HAMBURGER BUTTON
\*------------------------------------*/



// Settings for Hamburger button

$hamburger-padding-x: 1rem;
$hamburger-padding-y: 1rem;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-color: $blue-dark;
$hamburger-types: (squeeze);

// Keep it visible, despite the menu appearing

button.hamburger {
  order: 1;
  margin-left: $pad;
  z-index: 99;
  background-color: #fff !important;
  @media all and (max-width: $bp-medium) {
    position: absolute;
    right: $pad;
    top: 50px;
  }
  @media all and (min-width: $bp-large) {
    display: none;
  }
}
