/*------------------------------------*\
    # SUBFOOTER
\*------------------------------------*/

.c-subfooter {
  background: $black;
  padding: $pad/2 $pad;
  @media all and (min-width: $bp-small) {
    display: flex;
    align-items: center;
    justify-content: space-between;  
  }
}
