/*------------------------------------*\
    # GLOBAL VARIABLES
\*------------------------------------*/

/* = Breakpoints */

$bp-small: 512px;
$bp-medium: 768px;
$bp-large: 1100px;

/* = Layout */

$container: 1280px; //  Site-wide container max-width
$pad: 4rem; // Primary padding

/* = Fonts */

$body-font: proxima-nova, Helvetica, Arial, sans-serif;
$heading-font: titling-gothic-fb, Helvetica, Arial, sans-serif;

/* = Colors */

$black: #000;
$blue-dark: #0E4271;
$blue-light: #1A7FC3;
$yellow: #D9D52B;
$green-light: #38B64A;
$green-dark: #1F6534;

/* = Animation Timings */

$easing: cubic-bezier(0.075, 0.82, 0.165, 1);
$timing-slow: 1s;
$timing-fast: 0.5s;

/* = Misc */
$radius: 4px;
