/*------------------------------------*\
    # TEXT
\*------------------------------------*/

.c-page {
  p {
    margin-bottom: 2.5rem;
  }
  em {
    font-style: italic !important;
  }
  ol {
    list-style: decimal inside none;
  }
  ul {
    list-style: square inside none;
  }
  li {
    padding-bottom: 1.5rem;
  }
  ol,
  ul {
    margin-bottom: 2.5rem;
  }
  ol li,
  ul li {
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
