/*------------------------------------*\
    # GLOBAL STYLESHEET
\*------------------------------------*/
/**
 * Based on
 *
 *  - reset.css 2.0 by Eric Meyer
      (public domain)
 *    http://meyerweb.com/eric/tools/css/reset/
 *
 *  - normalize.css 8.0.1 by Nicolas Gallagher and Jonathan Neal
 *    (licensed under MIT)
 *    https://github.com/necolas/normalize.css
 *
 *  - Based on Reboot from Bootstrap 4.2.1
 *    (licensed under MIT)
 *    https://github.com/twbs/bootstrap
 */
/**
 * IE10+ doesn't honor `<meta name="viewport">` in some cases
 */
@-ms-viewport {
  width: device-width; }

/**
 * general reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/**
 * HTML5 display-role reset for older browsers
 */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, summary {
  display: block; }

/**
 * inherit box model for all elements
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * html root rules
 * 1. set border-box for inheritance
 * 2. avoid 300ms click delay on touch devices that support the `touch-action`
 *    CSS property
 * 3. Prevent adjustments of font size after orientation changes in IE, on
 *    Windows Phone and iOS.
 * 4. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
 *    so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
 * 5. Change the default tap highlight to be completely transparent in iOS.
 */
html {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  touch-action: manipulation;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 4 */
  -ms-overflow-style: scrollbar;
  /* 5 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/**
 * body rules
 * 1. reset line-height to 1
 * 2. set base font-family to sans-serif
 * 3. Set an explicit initial text-align value so that we can later use the
 *    `inherit` value on things like `<th>` elements.
 */
body {
  /* 1 */
  line-height: 1;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  text-align: left; }

/**
 * Lists
 */
ol, ul {
  list-style: none; }

/**
 * Quotes
 */
blockquote, q {
  quotes: none; }

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none; }

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

/**
 * Table Headers
 * 1. Matches default `<td>` alignment by inheriting from the `<body>`, or the
 *    closest parent with a set `text-align`.
 */
th {
  /* 1 */
  text-align: inherit; }

/**
 * Horizontal Lines
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  overflow: visible; }

/**
 * Preformatted Text
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Don't allow content to break outside
 * 3. We have @viewport set which causes scrollbars to overlap content in IE11
 *    and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to
 *    counteract.
 */
pre,
code,
kbd,
samp {
  /* 1 */
  font-family: monospace, monospace; }

pre {
  /* 2 */
  overflow: auto;
  /* 3 */
  -ms-overflow-style: scrollbar; }

/**
 * Links
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  /* 1 */
  background-color: transparent;
  /* 2 */
  -webkit-text-decoration-skip: objects; }

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 * 3. Add explicit cursor to indicate changed behavior.
 * 4. Prevent the text-decoration to be skipped.
 */
abbr[title] {
  /* 1 */
  border-bottom: none;
  /* 2 */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* 3 */
  cursor: help;
  /* 4 */
  text-decoration-skip-ink: none; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/**
 * Hide SVG overflow in IE
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * Remove the default `border-radius` that macOS Chrome adds.
 * Details at https://github.com/twbs/bootstrap/issues/24093
 */
button {
  border-radius: 0; }

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 * Credit: https://github.com/suitcss/base/
 */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/**
 * form element resets
 * 1. Remove the margin in Firefox and Safari
 * 2. inherit font rules
 */
input,
button,
select,
optgroup,
textarea {
  /* 1 */
  margin: 0;
  /* 2 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="reset"],
[type="submit"],
[type="button"] {
  /* 2 */
  -webkit-appearance: button; }

/**
 * Remove the default appearance of temporal inputs to avoid a Mobile Safari
 * bug where setting a custom line-height prevents text from being vertically
 * centered within the input.
 * See https://bugs.webkit.org/show_bug.cgi?id=139848
 * and https://github.com/twbs/bootstrap/issues/11266
 */
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
  overflow: auto;
  resize: vertical; }

/**
 * Show the overflow in IE.
 */
button,
input {
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button,
select {
  text-transform: none; }

/**
 * Remove the inheritance of word-wrap in Safari.
 * See https://github.com/twbs/bootstrap/issues/24990
 */
select {
  word-wrap: normal; }

/**
 * Remove inner border and padding from Firefox, but don't restore the outline
 * like Normalize.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * 1. Add the correct box sizing in IE 10-
 * 2. Remove the padding in IE 10-
 */
input[type="radio"],
input[type="checkbox"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
  outline: 0 !important; }

/**
 * Browsers set a default `min-width: min-content` on fieldsets,
 * unlike e.g. `<div>`s, which have `min-width: 0` by default.
 * So we reset that to ensure fieldsets behave more like a standard block element.
 * See https://github.com/twbs/bootstrap/issues/12359
 * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
 */
fieldset {
  min-width: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Set display to block for all browsers
 */
legend {
  /* 1 */
  max-width: 100%;
  white-space: normal;
  /* 2 */
  color: inherit;
  /* 3 */
  display: block; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  padding: 0; }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  /* 1 */
  -webkit-appearance: textfield;
  /* 2 */
  outline-offset: -2px; }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
  font: inherit; }

/**
 * Correct element display for output
 */
output {
  display: inline-block; }

/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

[hidden] {
  display: none; }

/*------------------------------------*\
    # GLOBAL VARIABLES
\*------------------------------------*/
/* = Breakpoints */
/* = Layout */
/* = Fonts */
/* = Colors */
/* = Animation Timings */
/* = Misc */
/*------------------------------------*\
    # BUTTON
\*------------------------------------*/
.c-btn {
  text-align: center;
  display: inline-block;
  font-family: titling-gothic-fb, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #38B64A;
  color: #fff;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 1rem 2rem; }
  .c-btn:focus, .c-btn:active, .c-btn:hover {
    background-color: #fff;
    color: #1F6534;
    border-color: #1F6534; }

.c-btn--secondary {
  background-color: #0E4271;
  font-weight: normal;
  font-size: 1.4rem;
  padding: 0.8rem 2rem; }
  .c-btn--secondary:focus, .c-btn--secondary:active, .c-btn--secondary:hover {
    background-color: #fff;
    color: #1A7FC3;
    border-color: #1A7FC3; }

/*------------------------------------*\
    # CARD
\*------------------------------------*/
.c-card {
  padding-bottom: 4rem; }
  .c-card__rating {
    color: #D9D52B;
    margin-bottom: 0 !important;
    font-size: 125%; }
  .c-card__description {
    margin-bottom: 0 !important; }
  @media all and (min-width: 768px) {
    .c-card {
      padding-bottom: 8rem; } }

.c-hero .c-card {
  background: #fffe;
  padding: 4rem; }
  @media all and (min-width: 1100px) {
    .c-hero .c-card {
      position: absolute;
      z-index: 1;
      right: 4rem;
      width: 60%;
      top: 50%;
      transform: translateY(-50%); } }

@media all and (min-width: 768px) {
  .c-card--vertical,
  .c-card--vertical-reverse {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .c-card--vertical .c-card__text,
    .c-card--vertical-reverse .c-card__text {
      min-width: 320px; }
    .c-card--vertical > *,
    .c-card--vertical-reverse > * {
      flex-basis: 50%; }
  .c-card--vertical {
    flex-direction: row-reverse; }
    .c-card--vertical .c-card__media {
      margin-left: 8rem; }
  .c-card--vertical-reverse {
    flex-direction: row; }
    .c-card--vertical-reverse .c-card__media {
      margin-right: 8rem; } }

/*------------------------------------*\
    # COLLAPSIBLE CONTAINER
\*------------------------------------*/
.c-collapse__control[type='checkbox'] {
  display: none; }

.c-collapse__trigger {
  display: block;
  font-family: titling-gothic-fb, Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 1rem 2rem;
  background: #1A7FC3;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

.c-collapse__trigger:hover {
  background: #0E4271; }

.c-collapse__trigger:before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1); }

.c-collapse__content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.075, 0.82, 0.165, 1); }

.c-collapse__content-inner {
  background: rgba(250, 224, 66, 0.2);
  border-bottom: 1px solid rgba(250, 224, 66, 0.45);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 1rem 2rem; }

.c-collapse__control:checked + .c-collapse__trigger + .c-collapse__content {
  max-height: 100vh;
  transition: max-height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

.c-collapse__control:checked + .c-collapse__trigger:before {
  transform: rotate(90deg) translateX(-3px); }

.c-collapse__control:checked + .c-collapse__trigger {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

/*------------------------------------*\
    # FOOTER
\*------------------------------------*/
/* Site Footer */
.c-footer {
  background: #000;
  padding: 4rem;
  border-top: solid 1px #2F3330; }

.c-footer,
.c-footer--nav {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap; }
  @media all and (min-width: 512px) {
    .c-footer,
    .c-footer--nav {
      flex-wrap: nowrap;
      justify-content: space-between; } }

.c-footer--nav > * {
  margin-bottom: 2rem; }
  @media all and (min-width: 512px) {
    .c-footer--nav > * {
      margin: 0; } }

/*------------------------------------*\
    # HAMBURGER BUTTON
\*------------------------------------*/
button.hamburger {
  order: 1;
  margin-left: 4rem;
  z-index: 99;
  background-color: #fff !important; }
  @media all and (max-width: 768px) {
    button.hamburger {
      position: absolute;
      right: 4rem;
      top: 50px; } }
  @media all and (min-width: 1100px) {
    button.hamburger {
      display: none; } }

/*------------------------------------*\
    # HEADER
\*------------------------------------*/
.c-header {
  position: relative;
  padding: 4rem;
  background: #fff;
  overflow: auto;
  border-bottom: solid 6px #1A7FC3;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap; }
  .c-header > *:first-child {
    margin-bottom: 2rem; }
  @media all and (min-width: 768px) {
    .c-header {
      justify-content: space-between;
      flex-wrap: nowrap; }
      .c-header > *:first-child {
        margin-bottom: 0; } }

.c-header.is-active {
  position: fixed;
  width: 100vw;
  z-index: 2; }

/*------------------------------------*\
	# HEADINGS
\*------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  font-family: titling-gothic-fb, Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1.3; }

/*
 * Sizing based on 1.25 Major Third scale
 * See: https://type-scale.com/
 */
h1 {
  font-size: 3.2rem; }

h2 {
  font-size: 2.5rem; }

h3 {
  font-size: 2.0rem; }

@media all and (min-width: 512px) {
  h1 {
    font-size: 3.4rem; }
  h2 {
    font-size: 2.7rem; }
  h3 {
    font-size: 2.2rem; } }

@media all and (min-width: 768px) {
  h1 {
    font-size: 4.0rem; }
  h2 {
    font-size: 3.0rem; }
  h3 {
    font-size: 2.8rem; } }

/*------------------------------------*\
    # HERO
\*------------------------------------*/
.c-hero {
  position: relative;
  border-bottom: solid 1px #ddd; }
  @media all and (min-width: 1100px) {
    .c-hero {
      border-bottom: 0; } }

.c-hero__image {
  width: 100vw;
  height: auto; }

/*------------------------------------*\
    # IMAGES
\*------------------------------------*/
img {
  max-width: 100%;
  height: auto;
  position: relative; }

/*------------------------------------*\
    # INFO CALLOUTS
\*------------------------------------*/
.c-info {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap; }
  @media all and (min-width: 512px) {
    .c-info {
      justify-content: flex-end;
      flex-wrap: nowrap; } }
  @media all and (min-width: 768px) {
    .c-info {
      margin-left: auto; } }

.c-info__icon {
  margin-right: 0.5rem;
  height: 40px;
  width: 40px; }
  .c-info__icon path {
    fill: #0E4271; }
    .c-info--inverted .c-info__icon path {
      fill: #fff; }

.c-info__item {
  display: flex;
  align-items: center;
  margin-right: 2rem; }
  .c-info__item:last-child {
    margin-right: 0; }

.c-info__text {
  white-space: nowrap;
  color: #0E4271;
  font-family: titling-gothic-fb, Helvetica, Arial, sans-serif;
  line-height: 1; }
  .c-info--inverted .c-info__text {
    color: #fff; }

.c-info__text--primary {
  font-weight: bold;
  font-size: 1.2rem; }

.c-info__text--secondary {
  font-size: 1.0rem; }

/*------------------------------------*\
    # LAYOUT TOOLS
\*------------------------------------*/
.l-container {
  max-width: 1280px;
  margin: 0 auto; }

.l-row {
  padding: 4rem;
  margin: 0 auto; }
  @media all and (min-width: 768px) {
    .l-row {
      display: flex;
      align-items: top;
      flex-wrap: wrap;
      justify-content: space-around; } }

.l-col {
  margin-bottom: 8rem; }
  @media all and (min-width: 768px) {
    .l-col {
      flex: 1 1 320px;
      margin-bottom: 0;
      margin-right: 8rem; }
      .l-col:last-child {
        margin-right: 0; } }

/*------------------------------------*\
    # LOGO
\*------------------------------------*/
@media all and (min-width: 512px) {
  .c-logo {
    margin-right: 4rem; } }

.c-logo a {
  display: block; }

@media all and (min-width: 512px) {
  .c-logo svg {
    width: auto; } }

.c-logo--inverted {
  width: auto; }

.c-footer .c-logo {
  width: 50%; }

/*------------------------------------*\
    # NAV
\*------------------------------------*/
.c-nav {
  text-transform: uppercase;
  transition: height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 2rem; }
  @media all and (min-width: 512px) {
    .c-nav {
      margin-bottom: 0; } }

.c-nav__item {
  display: inline-block;
  margin-right: 4rem;
  font-size: 1.4rem; }
  @media all and (min-width: 1100px) and (max-width: 1220px) {
    .c-nav__item {
      font-size: 1.2rem;
      margin-right: 1.6rem; } }
  .c-nav__item:last-child {
    margin-right: 0; }

.c-nav__link {
  padding: 0.5rem 0 0.4rem;
  font-family: titling-gothic-fb, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  color: #0E4271;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative; }
  .c-nav__link:before {
    content: " ";
    position: absolute;
    right: -14px;
    width: 8px;
    height: 8px;
    top: calc(50%-3px);
    visibility: hidden;
    opacity: 0;
    background-color: #1A7FC3;
    border-radius: 50% 50%;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .c-nav__link:after {
    content: " ";
    position: absolute;
    background-color: #1A7FC3;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .c-nav__link:focus:before, .c-nav__link:hover:before,
  .is-active .c-nav__link:before {
    visibility: visible;
    opacity: 0.5;
    transition: all 0.5s cubic-bezier(0.95, 0.05, 0.795, 0.035); }
  .c-nav__link:focus:after, .c-nav__link:hover:after,
  .is-active .c-nav__link:after {
    width: 100%;
    visibility: visible; }
  .c-nav__link:hover, .c-nav__link:focus,
  .is-active .c-nav__link {
    color: #1A7FC3;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    text-decoration: none; }

.c-nav--inverted .c-nav__link {
  color: #38B64A; }
  .is-active .c-nav--inverted .c-nav__link, .c-nav--inverted .c-nav__link:hover, .c-nav--inverted .c-nav__link:focus {
    color: #fff;
    background-color: transparent; }
  .c-nav--inverted .c-nav__link:before {
    background-color: #38B64A; }
  .c-nav--inverted .c-nav__link:after {
    background-color: #1F6534; }

/* Blur body when menu is active */
@media all and (max-width: 1100px) {
  .is-menu-active .c-page,
  .is-menu-active .c-footer {
    filter: blur(5px); } }

/* Collapsing Nav in header */
@media all and (max-width: 1100px) {
  .c-header .c-nav {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    left: 0;
    right: 0;
    top: 16rem;
    bottom: 0;
    background: #fffe; }
    .c-header .c-nav .c-nav__item {
      font-size: 3rem;
      margin: 0;
      display: block;
      text-align: center;
      border-bottom: solid 1px #1A7FC3; }
      .c-header .c-nav .c-nav__item:first-child {
        border-top: solid 1px #1A7FC3; }
    .c-header .c-nav .c-nav__link {
      display: block;
      padding: 2rem 4rem; }
    .is-menu-active .c-header .c-nav {
      display: block; } }

@media all and (max-width: 768px) {
  .c-header .c-nav {
    top: 20rem !important; } }

body.is-menu-active {
  width: 100%;
  position: fixed; }

/*------------------------------------*\
    # PAGE
\*------------------------------------*/
.c-page__title {
  color: #fff;
  background-color: #0E4271;
  padding: 4rem;
  text-transform: uppercase; }
  @media all and (min-width: 1100px) {
    .c-page__title {
      padding-top: 12rem;
      padding-bottom: 8rem; } }

.c-page__content {
  background: #fff;
  margin: 0 auto;
  padding: 4rem;
  position: relative; }
  @media all and (min-width: 1100px) {
    .c-page__content {
      padding: 8rem;
      margin-top: -4rem; } }

/*------------------------------------*\
    # SOCIAL
\*------------------------------------*/
.c-social {
  display: flex;
  align-items: center;
  margin-top: 1rem; }
  @media all and (min-width: 512px) {
    .c-social {
      margin-top: 0; } }

.c-social__item {
  margin-right: 2rem; }
  .c-social__item:last-child {
    margin-right: 0; }

.c-social__link {
  fill: #fff;
  transition: fill 1s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .c-social__link:hover path {
    fill: #38B64A !important;
    transition: fill 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*------------------------------------*\
    # SUBFOOTER
\*------------------------------------*/
.c-subfooter {
  background: #000;
  padding: 2rem 4rem; }
  @media all and (min-width: 512px) {
    .c-subfooter {
      display: flex;
      align-items: center;
      justify-content: space-between; } }

/*------------------------------------*\
    # TEXT
\*------------------------------------*/
.c-page p {
  margin-bottom: 2.5rem; }

.c-page em {
  font-style: italic !important; }

.c-page ol {
  list-style: decimal inside none; }

.c-page ul {
  list-style: square inside none; }

.c-page li {
  padding-bottom: 1.5rem; }

.c-page ol,
.c-page ul {
  margin-bottom: 2.5rem; }

.c-page ol li:last-child,
.c-page ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0; }

/*------------------------------------*\
    # TYPOGRAPHY
\*------------------------------------*/
html {
  font-size: 62.5%;
  /* = 10px */ }

body {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 1.7rem;
  /* = 17px, Mininum font size */
  line-height: 1.5; }

/*------------------------------------*\
    # UTILITY CLASSES
\*------------------------------------*/
@media all and (min-width: 768px) {
  .u-hide-desktop {
    display: none !important; } }

@media all and (max-width: 768px) {
  .u-hide-mobile {
    display: none !important; } }

.u-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }

.u-hide {
  display: none !important; }

.u-larger {
  font-size: 125%; }

.u-smaller {
  font-size: 80%; }

@media all and (min-width: 512px) {
  .u-align-right {
    max-width: 300px;
    float: right;
    margin-left: 4rem;
    margin-bottom: 4rem; } }

.u-m1 {
  margin: 4rem; }

.u-m2 {
  margin: 8rem; }

.u-m3 {
  margin: 12rem; }

.u-m4 {
  margin: 16rem; }

.u-mt1 {
  margin-top: 4rem; }

.u-mt2 {
  margin-top: 8rem; }

.u-mt3 {
  margin-top: 12rem; }

.u-mt4 {
  margin-top: 16rem; }

.u-mb1 {
  margin-bottom: 4rem; }

.u-mb2 {
  margin-bottom: 8rem; }

.u-mb3 {
  margin-bottom: 12rem; }

.u-mb4 {
  margin-bottom: 16rem; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1rem 1rem;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #0E4271; }

.hamburger-box {
  width: 30px;
  height: 21px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #0E4271;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -9px; }
  .hamburger-inner::after {
    bottom: -9px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
