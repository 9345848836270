/*------------------------------------*\
    # NAV
\*------------------------------------*/

.c-nav {
  text-transform: uppercase;
  transition: height .25s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  margin-bottom: $pad/2;
  @media all and (min-width: $bp-small) {
    margin-bottom: 0;    
  }
}

.c-nav__item {
  display: inline-block;
  margin-right: $pad;
  font-size: 1.4rem;
  @media all and (min-width: 1100px) and (max-width: 1220px) {
    font-size: 1.2rem;
    margin-right: $pad/2.5;
  }
  &:last-child {
    margin-right: 0;
  }
}

.c-nav__link {
  padding: 0.5rem 0 0.4rem;
  font-family: $heading-font;
  font-weight: bold;
  text-decoration: none;
  color: $blue-dark;
  transition: all $timing-slow $easing;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    right: -14px;
    width: 8px;
    height: 8px;
    top: calc(50%-3px);
    visibility: hidden;
    opacity: 0;
    background-color: $blue-light;
    border-radius: 50% 50%;
    transition: all $timing-fast $easing;
  }
  &:after {
    content: " ";
    position: absolute;
    background-color: $blue-light;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    visibility: hidden;
    transition: all $timing-fast $easing;
  }
  &:focus:before,
  &:hover:before,
  .is-active &:before {
    visibility: visible;
    opacity: 0.5;
    transition: all $timing-fast cubic-bezier(0.95, 0.05, 0.795, 0.035);
  }
  &:focus:after,
  &:hover:after,
  .is-active &:after {
    width: 100%;
    visibility: visible;
  }
  &:hover,
  &:focus,
  .is-active & {
    color: $blue-light;
    //background-color: $blue-light;
    transition: all $timing-fast $easing;
    text-decoration: none;
  }
}

.c-nav--inverted .c-nav__link {
  color: $green-light;
  .is-active &,
  &:hover,
  &:focus {
    color: #fff;
    background-color: transparent;
  }
  &:before {
    background-color: $green-light;
  }
  &:after {
    background-color: $green-dark;
  }
}

/* Blur body when menu is active */

@media all and (max-width: $bp-large) {
  .is-menu-active .c-page,
  .is-menu-active .c-footer {
    filter: blur(5px);
  }
}

/* Collapsing Nav in header */
@media all and (max-width: $bp-large) {
  .c-header .c-nav {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    left: 0;
    right: 0;
    top: $pad*4;
    bottom: 0;
    background: #fffe;
    //padding-top: $pad*2;
    .c-nav__item {
      font-size: 3rem;
      margin: 0;
      display: block;
      text-align: center;
      border-bottom: solid 1px $blue-light;
      &:first-child {
        border-top: solid 1px $blue-light;
      }
    } 
    .c-nav__link {
      display: block;
      padding: $pad/2 $pad;
    }
    .is-menu-active & {
      display: block;
    }
  }
}
@media all and (max-width: $bp-medium) {
  .c-header .c-nav {
    top: $pad*5 !important;
  }
}

body.is-menu-active {
  width: 100%;
  position: fixed; 
}
