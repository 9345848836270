/*------------------------------------*\
    # CARD
\*------------------------------------*/

.c-card {
  padding-bottom: $pad;
  &__rating {
    color: $yellow;
    margin-bottom: 0 !important;
    font-size: 125%;
  }
  &__description {
    margin-bottom: 0 !important;
  }
  @media all and (min-width: $bp-medium) {
    padding-bottom: $pad*2;
  }
}

// Card variant for hero
.c-hero .c-card {
  background: #fffe;
  padding: $pad;
  @media all and (min-width: $bp-large) {
    position: absolute;
    z-index: 1;
    right: $pad;
    width: 60%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media all and (min-width: $bp-medium) {
  // Vertically aligned variants
  .c-card--vertical,
  .c-card--vertical-reverse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .c-card__text {
      min-width: 320px;
    }
    & > * {
      flex-basis: 50%;
    }
  }

  // Vertical aligned card
  .c-card--vertical {
    flex-direction: row-reverse; 
    .c-card__media {
      margin-left: $pad *2;
    }
  }

  // Vertically aligned card (reverse text/media)
  .c-card--vertical-reverse {
    flex-direction: row; 
    .c-card__media {
      margin-right: $pad *2;
    }
  }
}
