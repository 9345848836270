/*------------------------------------*\
    # LAYOUT TOOLS
\*------------------------------------*/

.l-container {
  max-width: $container;
  margin: 0 auto;
}

.l-row {
  padding: $pad;
  margin: 0 auto;
  @media all and (min-width: $bp-medium) {
    display: flex;
    align-items: top;  
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.l-col {
  margin-bottom: $pad * 2;
  @media all and (min-width: $bp-medium) {
    flex: 1 1 320px;
    margin-bottom: 0;
    margin-right: $pad*2;
    &:last-child {
      margin-right: 0;
    }
  }
}
  