/*------------------------------------*\
    # COLLAPSIBLE CONTAINER
\*------------------------------------*/

// Clickable button
.c-collapse__control[type='checkbox'] {
  display: none;
}
.c-collapse__trigger {
  display: block;
  font-family: $heading-font;
  text-align: center;
  padding: $pad/4 $pad/2;
  background: $blue-light;
  color: #fff;
  cursor: pointer;
  border-radius: $radius;
  transition: all $timing-fast $easing;
}
.c-collapse__trigger:hover {
  background: $blue-dark;
}

// Dropdown Arrow

.c-collapse__trigger:before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform $timing-slow $easing;
}

// Content Panel

.c-collapse__content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height $timing-slow $easing;
}

.c-collapse__content-inner {
  background: rgba(250, 224, 66, .2);
  border-bottom: 1px solid rgba(250, 224, 66, .45);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: $pad/4 $pad/2;
}
.c-collapse__control:checked + .c-collapse__trigger + .c-collapse__content {
  max-height: 100vh;
  transition: max-height $timing-fast $easing;
}
.c-collapse__control:checked + .c-collapse__trigger:before {
  transform: rotate(90deg) translateX(-3px);
}

.c-collapse__control:checked + .c-collapse__trigger {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
