/*------------------------------------*\
    # UTILITY CLASSES
\*------------------------------------*/

.u-hide-desktop {
  @media all and (min-width: $bp-medium) {
    display: none !important;
  }
}
.u-hide-mobile {
  @media all and (max-width: $bp-medium) {
    display: none !important;
  }
}

.u-sr-only{
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.u-hide{
  display: none !important;
}
.u-larger {
  font-size: 125%;
}
.u-smaller {
  font-size: 80%;
}

@media all and (min-width: $bp-small) {
  .u-align-right {
    max-width: 300px;
    float: right;
    margin-left: $pad;
    margin-bottom: $pad;  
  }
}

.u-m1 {margin: $pad;}
.u-m2 {margin: $pad*2;}
.u-m3 {margin: $pad*3;}
.u-m4 {margin: $pad*4;}

.u-mt1 {margin-top: $pad;}
.u-mt2 {margin-top: $pad*2;}
.u-mt3 {margin-top: $pad*3;}
.u-mt4 {margin-top: $pad*4;}

.u-mb1 {margin-bottom: $pad;}
.u-mb2 {margin-bottom: $pad*2;}
.u-mb3 {margin-bottom: $pad*3;}
.u-mb4 {margin-bottom: $pad*4;}
