/*------------------------------------*\
    # PAGE
\*------------------------------------*/

.c-page {
}
.c-page__title {
  color: #fff;
  background-color: $blue-dark;
  padding: $pad;
  text-transform: uppercase;
  @media all and (min-width: $bp-large) {
    padding-top: $pad*3;
    padding-bottom: $pad*2;
  }
}
.c-page__content {
  background: #fff;
  margin: 0 auto;
  padding: $pad;
  position: relative;
  @media all and (min-width: $bp-small) {
    
  }
  @media all and (min-width: $bp-large) {
    padding: $pad*2;
    margin-top: -$pad;  
  }
}
