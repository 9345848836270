/*------------------------------------*\
    # BUTTON
\*------------------------------------*/

.c-btn {
  text-align: center;
  display: inline-block;
  font-family: $heading-font;
  font-weight: bold;
  font-size: 1.6rem;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  transition: all $timing-slow $easing;
  background-color: $green-light;
  color: #fff;
  border: solid 1px transparent;
  border-radius: $radius;
  padding: $pad/4 $pad/2;
  &:focus,
  &:active,
  &:hover {
    background-color: #fff;
    color: $green-dark;
    border-color: $green-dark;
  }
} // .c-btn

.c-btn--secondary {
  background-color: $blue-dark;
  font-weight: normal;
  font-size: 1.4rem;
  padding: $pad/5 $pad/2;
  &:focus,
  &:active,
  &:hover {
    background-color: #fff;
    color: $blue-light;
    border-color: $blue-light;
  }
}
