/*------------------------------------*\
    # TYPOGRAPHY
\*------------------------------------*/

html {
  font-size: 62.5%; /* = 10px */
}

body {
  font-family: $body-font;
  font-size: 1.7rem;    /* = 17px, Mininum font size */
  line-height: 1.5;
}

